import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Context = () =>  {
    const settings = {
      focusOnSelect: true,
      arrows: false,
      dots: true,
      infinite: true,
      centerMode: true,
      centerPadding: "0",
      slidesToShow: 1,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            centerMode: false,
            variableWidth: false,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <Slider {...settings}>
        <div className="article">
          <div className="block">
          <h2>空間　―　Space</h2>
          <p>一般的に「空間」とは、人が利用する３次元空間を指す言葉として広く用いられている。一方、数学における「空間」とは「集合にある構造を与えたもの」であり、それは有形／無形を問わない事物の固有の集まり方を指す。空間の定義をこの数学の用法に倣うことで、「空間」はより拡がりをもった概念として扱うことができる。そしてこの場合、空間をつくることとは、ひと・もの・場所・環境・産業・文化・歴史・データなどの集合に、未だ存在したことのないユニークな結びつきを与えることと同義である。</p>
          </div>
        </div>
        <div className="article">
          <div className="block">
          <h2>現実　―　Reality</h2>
          <p>物理空間が生み出す現実と情報空間が生み出す現実は、情報技術の発展により、シームレスに往来したり重ね合わせることのできる重層的な現実へと進化する。これまで物理空間における建築が一手に担ってきた、一般的な意味での３次元空間の成立条件は飛躍的に拡がり、同時に、人間と空間はよりインタラクティブかつ不可分な関係を築く。結果として、これまで存在しなかった空間が数多く発生し、そのうちいくつかは人類と人間社会の進化に決定的な影響を及ぼすだろう。</p>
          </div>
        </div>
        <div className="article">
          <div className="block">
          <h2>社会　―　Society</h2>
          <p>不特定多数のためにつくられる空間には、不特定多数が交換可能な記号によって構成し、ユニークであることを忌避しようとする力が働く。一方、道具の進化と民主化により、空間は個人や特定少数が自ら構想・建設し、運営・維持可能なものへと変わりつつあり、この流れは情報空間から始まり、最終的に物理空間にまで及ぶ。人間社会は、特定少数が立ち上げるユニークな「小さな公共空間(Microverse?)」が折り重なることで、より色彩豊かなものになるだろう。</p>
          </div>
        </div>
        <div className="article">
          <div className="block">
          <h2>記号　―　Sign</h2>
          <p>情報は、相対的に変化量が少ない部分は圧縮されて認知されるという特性を持つ。たとえば、陽光により細かな陰影を持つ雄弁な白い壁も、その上に赤い円を塗ればたちまち日本国旗という既知の「記号」に収斂する。記号とは理解の仕方が定型化した情報である。一方、表現芸術には、白紙の上に描き込んで加算していくものと、現前する世界からノイズを取り除くことで減算していくものの２つの極と、そのあいだがあり、いずれも既知の記号として処理される空間の外を目指すものである。</p>
          </div>
        </div>
        <div className="article">
          <div className="block">
          <h2>情報　―　Infomation</h2>
          <p>情報とは事物を交換可能なデータに圧縮したものであり、ひとつのデータに格納できる情報量は、それを交換する人数に概ね反比例する。"dog" という言葉は、数億人が正しく内容を送受信する上では犬一般の概念を指すが、特定少数のあいだでは情景・匂い・触覚・記憶などより多くの情報を格納することもできる。また、情報とはその理解の仕方を指定するメタ情報を持つものであり、芸術がキャプションをつけることで芸術作品になるように、あらゆるものの見え方を制御する力を持つ。</p>
          </div>
        </div>
        <div className="article">
          <div className="block">
          <h2>知覚　―　Perception</h2>
          <p>形と空間を知覚するのは、個々の人間の感覚器と脳である。よって知覚もまた設計対象となる。必要なことは、その空間を正しく観測する機会を、知覚する主体に対して等しく提供することである。言い換えれば、空間を未知のものとして受け取るために知覚を初期化することであり、その実現を形のうちに計画することである。</p>
          </div>
        </div>
        <div className="article">
          <div className="block">
          <h2>形　―　Shape</h2>
          <p>これまで空間と呼ばれてきた、ある物理的オブジェクトによって囲われた三次元空間は、むしろ「形」と呼ぶべきである。形とは、Geometry(位置・形状など)、Material(材質・色など)、Environment(光・空気・熱・音・人の活動など)の３つで決まる操作可能な対象であり、事物の固有の結びつき方が記述された具体物である。形とは空間の構造そのもののことであり、形と空間とはどちらかが先んじて存在するものではなく、一のものとして同時に立ち上がる。</p>
          </div>
        </div>
      </Slider>
    );
  }

  export default Context;
